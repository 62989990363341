import * as yup from 'yup'

import {
  CellPhoneCountryCodeOptions,
  PhoneNumberFormValues,
} from '~components/PhoneNumber/PhoneNumber.types'
import { Country } from '~constants/types'

// remember updating progressUserSchema when changing this enum
export enum OnboardingType {
  DigitalOnboarding = 'digitalOnboarding',
  DigitalOnboardingPersonalizationQuiz = 'digitalOnboardingPersonalizationQuiz',
  VirtualAppointmentOnboarding = 'virtualAppointmentOnboarding',
}

/**
 * Used within {@link file://./signupContext.ts#SignupContext}. The impact on
 * rejoining the flow should be considered when making changes. Currently saved
 * context will be merged with the current machine context.
 *
 * TODO: better typing of date strings.
 * see https://blog.logrocket.com/handing-date-strings-typescript/
 */
export interface User extends PhoneNumberFormValues {
  accessCode: string
  address: Address
  communicationsOptIn: boolean
  dateOfBirth: string // YYYY-MM-DD
  email: string
  ethnicity: string
  firstName: string
  genderIdentity?: string
  genderPronouns?: string
  isMinor: boolean
  language: Language
  lastName: string
  onboardingType?: OnboardingType
  password: string
  planGroupNo?: string
  planMemberId?: string
  sex?: 'male' | 'female' | 'other'
  zaIdNumber: string
}

export interface Address {
  address1: string
  address2: string
  city: string
  country: Country
  postalCode: string
  province: string
}

/**
 * The ELID (Eligibility ID) is the list of identifying fields used to match a
 * prospective user in the eligibility records provided by clients.
 */
export type ELID = Pick<User, 'firstName' | 'lastName' | 'dateOfBirth'> &
  Pick<Address, 'country' | 'postalCode'> &
  Partial<Pick<Address, 'province'>>

const addressSchema: yup.ObjectSchema<Address> = yup.object().shape({
  address1: yup.string().defined(),
  address2: yup.string().defined(),
  city: yup.string().defined(),
  country: yup.mixed<Country>().oneOf(Object.values(Country)).required(),
  postalCode: yup.string().defined(),
  province: yup.string().defined(),
})

/**
 * Used within {@link file://./../services/authServices.ts#B2BOnboardingProgress}.
 *
 * The password is not required as it should not be present.
 */
export type ProgressUser = Omit<User, 'password'> & { password?: string | null }
export const progressUserSchema: yup.ObjectSchema<ProgressUser> = yup
  .object()
  .shape(
    {
      accessCode: yup.string().defined(),
      address: addressSchema,
      cellPhoneCountryCode: yup
        .mixed<User['cellPhoneCountryCode']>()
        .oneOf([...CellPhoneCountryCodeOptions])
        .required(),
      cellPhoneNumber: yup
        .string()
        .defined()
        .when('email', {
          is: (email: string) => !email,
          then: (schema) => schema.required(), // either 'email' or 'cellPhoneNumber' is required
        }),
      communicationsOptIn: yup.boolean().required(),
      dateOfBirth: yup.string().defined(),
      email: yup
        .string()
        .trim()
        .defined()
        .when('cellPhoneNumber', {
          is: (cellPhoneNumber: string) => !cellPhoneNumber,
          then: (schema) => schema.required(), // either 'email' or 'cellPhoneNumber' is required
        }),
      ethnicity: yup.string().defined(),
      firstName: yup.string().trim().required(),
      genderIdentity: yup.string().optional(),
      genderPronouns: yup.string().optional(),
      isMinor: yup.boolean().required(),
      language: yup.mixed<User['language']>().oneOf(['en', 'es']).required(),
      lastName: yup.string().trim().required(),
      onboardingType: yup
        .mixed<OnboardingType>()
        .oneOf([
          OnboardingType.DigitalOnboarding,
          OnboardingType.DigitalOnboardingPersonalizationQuiz,
          OnboardingType.VirtualAppointmentOnboarding,
        ])
        .optional(),
      password: yup.string().notRequired(),
      planGroupNo: yup.string().optional(),
      planMemberId: yup.string().optional(),
      sex: yup
        .mixed<Exclude<User['sex'], undefined>>()
        .oneOf(['male', 'female', 'other'])
        .optional(),
      zaIdNumber: yup.string().defined(),
    },
    [['cellPhoneNumber', 'email']] // https://github.com/jquense/yup/issues/1565
  )
