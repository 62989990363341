import { ThemeOptions, createTheme } from '@mui/material/styles'

import ESRebondGrotesqueBold from '~fonts/ESRebondGrotesque/ESRebondGrotesque-Bold.woff2'
import ESRebondGrotesqueBoldItalic from '~fonts/ESRebondGrotesque/ESRebondGrotesque-BoldItalic.woff2'
import ESRebondGrotesqueItalic from '~fonts/ESRebondGrotesque/ESRebondGrotesque-Italic.woff2'
import ESRebondGrotesqueLight from '~fonts/ESRebondGrotesque/ESRebondGrotesque-Light.woff2'
import ESRebondGrotesqueLightItalic from '~fonts/ESRebondGrotesque/ESRebondGrotesque-LightItalic.woff2'
import ESRebondGrotesqueMedium from '~fonts/ESRebondGrotesque/ESRebondGrotesque-Medium.woff2'
import ESRebondGrotesqueMediumItalic from '~fonts/ESRebondGrotesque/ESRebondGrotesque-MediumItalic.woff2'
import ESRebondGrotesqueRegular from '~fonts/ESRebondGrotesque/ESRebondGrotesque-Regular.woff2'
import SuisseNeueRegular from '~fonts/SuisseNeue/SuisseNeue-Regular.otf'
import SuisseNeueLight from '~fonts/SuisseNeue/SuisseNeue-Regular.otf'
import SuisseNeueMedium from '~fonts/SuisseNeue/SuisseNeue-Regular.otf'
import SuisseNeueRegularItalic from '~fonts/SuisseNeue/SuisseNeue-RegularItalic.otf'
import SuisseNeueLightItalic from '~fonts/SuisseNeue/SuisseNeue-RegularItalic.otf'
import SuisseNeueMediumItalic from '~fonts/SuisseNeue/SuisseNeue-RegularItalic.otf'

import colors from './colors'

const ESRebondGrotestqueFonts = 'ES Rebond Grotestque, Helvetica, Arial'

const MUI_LIST_PADDING = '12px 10px'

declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    border?: PaletteColorOptions
    altBackground?: PaletteColorOptions
    accessibility?: PaletteColorOptions
  }
  interface Palette {
    border: PaletteColor
    altBackground: PaletteColor
    accessibility: PaletteColor
  }
}

const defaultThemeOptions: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'ES Rebond Grotestque';
          src: url('${ESRebondGrotesqueRegular}')
            format('woff2');
          font-weight: normal;
          font-style: normal;
        }

        @font-face {
          font-family: 'ES Rebond Grotestque';
          src: url('${ESRebondGrotesqueItalic}')
            format('woff2');
          font-weight: normal;
          font-style: italic;
        }

        @font-face {
          font-family: 'ES Rebond Grotestque';
          src: url('${ESRebondGrotesqueLight}')
            format('woff2');
          font-weight: lighter;
          font-style: normal;
        }

        @font-face {
          font-family: 'ES Rebond Grotestque';
          src: url('${ESRebondGrotesqueLightItalic}')
            format('woff2');
          font-weight: lighter;
          font-style: italic;
        }

        @font-face {
          font-family: 'ES Rebond Grotestque';
          src: url('${ESRebondGrotesqueMedium}')
            format('woff2');
          font-weight: 500;
          font-style: normal;
        }

        @font-face {
          font-family: 'ES Rebond Grotestque';
          src: url('${ESRebondGrotesqueMediumItalic}')
            format('woff2');
          font-weight: 500;
          font-style: italic;
        }

        @font-face {
          font-family: 'ES Rebond Grotestque';
          src: url('${ESRebondGrotesqueBold}')
            format('woff2');
          font-weight: bold;
          font-style: normal;
        }

        @font-face {
          font-family: 'ES Rebond Grotestque';
          src: url('${ESRebondGrotesqueBoldItalic}')
            format('woff2');
          font-weight: bold;
          font-style: italic;
        }

        @font-face {
          font-family: 'Suisse Neue';
          src: url('${SuisseNeueRegular}')
            format('opentype');
          font-weight: normal;
          font-style: normal;
        }

        @font-face {
          font-family: 'Suisse Neue';
          src: url('${SuisseNeueRegularItalic}')
            format('opentype');
          font-weight: normal;
          font-style: italic;
        }

        @font-face {
          font-family: 'Suisse Neue';
          src: url('${SuisseNeueLight}')
            format('opentype');
          font-weight: lighter;
          font-style: normal;
        }

        @font-face {
          font-family: 'Suisse Neue';
          src: url('${SuisseNeueLightItalic}')
            format('opentype');
          font-weight: lighter;
          font-style: italic;
        }

        @font-face {
          font-family: 'Suisse Neue';
          src: url('${SuisseNeueMedium}')
            format('opentype');
          font-weight: 500;
          font-style: normal;
        }

        @font-face {
          font-family: 'Suisse Neue';
          src: url('${SuisseNeueMediumItalic}')
            format('opentype');
          font-weight: 500;
          font-style: italic;
        }
      `,
    },
    MuiListSubheader: {
      defaultProps: {
        className: 'pel-typography-o2 pel-colour-global-primary',
        sx: {
          padding: MUI_LIST_PADDING,
          borderBottom: '1px solid' + colors.outline.default,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '24px',
          padding: MUI_LIST_PADDING,
          '&:hover': {
            backgroundColor: colors.background['option-hover'],
          },
          '&.Mui-selected': {
            backgroundColor: colors.background['option-hover'],
          },
          '&.Mui-selected:hover': {
            backgroundColor: colors.background['option-hover'],
          },
          '&.Mui-focusVisible': {
            backgroundColor: colors.background['option-hover'],
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: {
          fontSize: '14px',
          lineHeight: '24px',
        },
        option: {
          fontSize: '14px',
          lineHeight: '24px',
          '&.MuiAutocomplete-option.Mui-focused': {
            backgroundColor: colors.background['option-hover'],
          },
          '&.MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: colors.background['option-hover'],
          },
          '&.MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            backgroundColor: colors.background['option-hover'],
          },
        },
        loading: {
          fontSize: '14px',
          lineHeight: '24px',
        },
        listbox: {
          '.MuiAutocomplete-option': {
            padding: MUI_LIST_PADDING, // Gets overridden if placed in option directly.
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: '10px',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
        color: colors.primary.black.default,
      },
    },
    MuiSelect: {
      styleOverrides: {
        filled: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'outlined',
      },
    },
    // TODO: [FLOW-367] Pelago checkbox and radio styling.
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.MuiCheckbox-colorError': {
            color: colors.alert.error.default,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.MuiRadio-colorError': {
            color: colors.alert.error.default,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontFamily: ESRebondGrotestqueFonts,
          fontSize: '16px',
          '&.Mui-selected': {
            fontWeight: 700,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid',
          borderBottomColor: colors.outline.default,
        },
        indicator: {
          height: '4px',
        },
      },
    },
  },
  palette: {
    text: {
      primary: colors.text.global.primary,
      secondary: colors.text.global.secondary,
      disabled: colors.text.global.disabled,
    },
    primary: {
      main: colors.primary.black.default,
      light: colors.primary.black.disabled,
      dark: colors.primary.black.pressed,
    },
    secondary: {
      main: colors.secondary.yellow.default,
      light: colors.secondary.yellow.disabled,
      dark: colors.secondary.yellow.pressed,
    },
    border: {
      main: colors.outline.default,
    },
    common: {
      black: colors.primary.black.default,
      white: colors.primary.white.default,
    },
    action: {
      active: colors.primary.black.default,
      hover: colors.primary.black.hover,
      selected: colors.primary.black.pressed,
      disabled: colors.primary.white.default,
      disabledBackground: colors.primary.black.disabled,
      focus: colors.primary.black.default,
    },
    altBackground: {},
    warning: {
      main: colors.alert.warning.default,
      light: colors.alert.warning.disabled,
      dark: colors.alert.warning.pressed,
    },
    error: {
      main: colors.alert.error.default,
      light: colors.alert.error.disabled,
      dark: colors.alert.error.pressed,
    },
    accessibility: {},
  },
  shape: {
    borderRadius: 2,
  },
  // TBC Confirm with design
  breakpoints: {
    values: {
      xs: 320,
      sm: 600,
      md: 1024,
      lg: 1200,
      xl: 1400,
    },
  },
}

/**
 * MUI theme object
 *
 * Design tokens taken from:
 * - https://www.figma.com/file/fQKAZqhlWVHGcufz1z2kt4/Pelago-Design-System-foundation?node-id=7859%3A7282&mode=dev
 *
 */
const theme = createTheme(defaultThemeOptions)

theme.typography.fontFamily = ESRebondGrotestqueFonts

theme.typography.h1 = {
  fontFamily: ESRebondGrotestqueFonts,
  fontSize: '32px',
  lineHeight: '44px',
  fontWeight: 'bold',
  [theme.breakpoints.up('sm')]: {
    fontSize: '48px',
    lineHeight: '64px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '72px',
    lineHeight: '88px',
  },
}
theme.typography.h2 = {
  fontFamily: ESRebondGrotestqueFonts,
  fontSize: '28px',
  lineHeight: '38px',
  fontWeight: 600,
  [theme.breakpoints.up('sm')]: {
    fontSize: '32px',
    lineHeight: '44px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '56px',
    lineHeight: '72px',
  },
}
theme.typography.h3 = {
  fontFamily: ESRebondGrotestqueFonts,
  fontSize: '24px',
  lineHeight: '36px',
  fontWeight: 600,
  [theme.breakpoints.up('sm')]: {
    fontSize: '28px',
    lineHeight: '40px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '48px',
    lineHeight: '64px',
  },
}
theme.typography.h4 = {
  fontFamily: ESRebondGrotestqueFonts,
  fontSize: '24px',
  lineHeight: '36px',
  fontWeight: 600,
  [theme.breakpoints.up('sm')]: {
    fontSize: '28px',
    lineHeight: '40px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '32px',
    lineHeight: '44px',
  },
}
theme.typography.h5 = {
  fontFamily: ESRebondGrotestqueFonts,
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 600,
  [theme.breakpoints.up('md')]: {
    fontSize: '24px',
    lineHeight: '36px',
  },
}
theme.typography.h6 = {
  fontFamily: ESRebondGrotestqueFonts,
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 600,
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    lineHeight: '32px',
  },
}
theme.typography.body1 = {
  fontFamily: ESRebondGrotestqueFonts,
  fontSize: '18px',
  lineHeight: '32px',
  fontWeight: 400,
}
theme.typography.body2 = {
  fontFamily: ESRebondGrotestqueFonts,
  fontSize: '16px',
  lineHeight: '28px',
  fontWeight: 400,
}

export default theme
