import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Box, BoxProps } from '@mui/material'
import { Fragment, ReactElement, ReactNode } from 'react'

import InfoIconSvg from '~icons/info.svg?react'

import './InfoBanner.scss'

export enum InfoBannerLevel {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Done = 'done',
}

export type InfoBannerProps = {
  header: string | ReactNode
  bannerContent: string | ReactNode | (string | ReactNode)[]
  level?: InfoBannerLevel
} & BoxProps

export default function InfoBanner(props: InfoBannerProps): ReactElement {
  const {
    header,
    bannerContent,
    level = InfoBannerLevel.Info,
    ...boxProps
  } = props

  return (
    <Box className={`info-banner ${level}`} {...boxProps}>
      <Box>
        {level === InfoBannerLevel.Done ? (
          <CheckCircleRoundedIcon />
        ) : (
          <InfoIconSvg
            className="info-banner__icon"
            role="img"
            aria-hidden="true"
          />
        )}
      </Box>

      <Box className="info-banner__content">
        <p className="info-banner__content__title">{header}</p>
        <div className="info-banner__content__description">
          {Array.isArray(bannerContent) ? (
            bannerContent.map((item, index) => (
              <Fragment key={index}>
                <p>{item}</p>
                {index !== bannerContent.length - 1 ? <hr /> : null}
              </Fragment>
            ))
          ) : (
            <p>{bannerContent}</p>
          )}
        </div>
      </Box>
    </Box>
  )
}
