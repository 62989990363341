import axios from 'axios'
import {
  differenceInYears,
  endOfDay,
  isBefore,
  isFuture,
  isSameDay,
  isToday,
  parse,
  startOfDay,
} from 'date-fns'
import { FormikErrors } from 'formik'
import {
  MINOR_PROGRAM_MIN_AGE_THRESHOLDS,
  TOBACCO_SUB_ADDICTIONS,
  TobaccoSubAddictions,
  TreatmentProgram,
  TreatmentProgramConfig,
} from 'global.constants'
import { FormEvent } from 'react'
import * as yup from 'yup'

import {
  ADDICTION_SELECTION_PARENT_ADDICTIONS,
  AddictionSelectionParentAddiction,
} from '~components/AddictionSelectorBasePage/AddictionSelectorBasePage'
import usStates, { USState } from '~constants/USStates'
import {
  CallCopyVariation,
  IncentiveType,
  StateAvailability,
} from '~constants/types'
import {
  JointPageName,
  trackContinueClicked,
  trackFieldBlurred,
} from '~services/analyticsService/analytics.service'
import {
  ProgressSignupContext,
  SignupContext,
} from '~services/signupMachine/types/signupContext'

/**
 * Helper function to determine if a given addiction is part of the tobacco learn program
 *
 * @param selectedAddiction
 * @returns boolean
 */
export function isTobaccoLearn(selectedAddiction?: TreatmentProgram): boolean {
  if (!selectedAddiction) {
    return false
  }
  return ['cigarettesLearn', 'eCigarettesLearn'].includes(selectedAddiction)
}

/**
 * Helper function to determine if a given addiction is of type tobacco
 *
 * @param selectedAddiction
 * @returns boolean
 */
export function isTobacco(selectedAddiction?: TreatmentProgram): boolean {
  if (!selectedAddiction) {
    return false
  }
  return [
    'cigarettesLearn',
    'eCigarettesLearn',
    'chewingTobacco',
    'cigarettes',
    'eCigarettes',
  ].includes(selectedAddiction)
}

export function calculateUserAge(dateOfBirth: string): number {
  const dateOfBirthDateObject = parse(dateOfBirth, 'yyyy-MM-dd', new Date())
  return differenceInYears(new Date(), dateOfBirthDateObject)
}

export function isMinorUS(
  dateOfBirth: string,
  stateCode: string
): boolean | undefined {
  const state = getUsStateFromStateCode(stateCode)
  if (!state) {
    throw new Error('No state. State required to determine age of majority')
  }
  return calculateUserAge(dateOfBirth) < state.ageOfMajority
}

/**
 * Returns true when date is NOT today/future
 * @param dateOfBirth string, format: 'yyyy-MM-dd'
 */
export function isPastDate(dateOfBirth: string): boolean {
  const parsedDate: Date = parse(dateOfBirth, 'yyyy-MM-dd', new Date())
  return !(isToday(parsedDate) || isFuture(parsedDate))
}

export function doesMeetAgeThresholdForProgramme(
  age: number,
  quitting: TreatmentProgram
): boolean {
  return MINOR_PROGRAM_MIN_AGE_THRESHOLDS[quitting] <= age
}

export function getUsStateFromStateCode(
  stateCode: string
): USState | undefined {
  return usStates.find((state) => state.code === stateCode)
}

/**
 * Helper function to determine whether the returned error status is a server error or that the request had timed out
 */
export function isInternalError(error: ResponseError): boolean {
  if (
    (axios.isAxiosError(error) &&
      [500, 503, 504, 0].includes(error.response?.status as number)) ||
    error.code === 'ECONNABORTED'
  ) {
    return true
  }
  return false
}

export function onlyTobaccoLearnProgramsEnabled(
  addictions: TreatmentProgramConfig
) {
  return (
    (addictions.eCigarettesLearn || addictions.cigarettesLearn) &&
    !addictions.alcohol &&
    !addictions.opioids
  )
}

export function onlyAlcoholProgramEnabled(
  treatmentProgramConfig: TreatmentProgramConfig
): boolean {
  return Object.entries(treatmentProgramConfig).every(
    ([treatmentProgram, isAvailable]) =>
      treatmentProgram === TreatmentProgram.Alcohol ? isAvailable : !isAvailable
  )
}

export function handleFormBlur(
  fieldName: string,
  pageName: JointPageName,
  validationError?: string
) {
  if (fieldName) {
    trackFieldBlurred(pageName, fieldName, validationError)
  }
}

export async function handleFormSubmit(
  e: FormEvent<HTMLFormElement>,
  pageName: JointPageName,
  formik: {
    validateForm: () => Promise<FormikErrors<object>>
    handleSubmit: (e: FormEvent<HTMLFormElement>) => void
  }
) {
  e.preventDefault()
  const validationErrors = await formik.validateForm()
  trackContinueClicked(pageName, Object.keys(validationErrors))
  formik.handleSubmit(e)
}

export function isParentAddiction(
  substance: string
): substance is AddictionSelectionParentAddiction {
  return ADDICTION_SELECTION_PARENT_ADDICTIONS.includes(
    substance as AddictionSelectionParentAddiction
  )
}

export function isTobaccoSubAddiction(
  substance: string
): substance is TobaccoSubAddictions {
  return TOBACCO_SUB_ADDICTIONS.includes(substance as TobaccoSubAddictions)
}

export enum OperatingSystem {
  'Android',
  'iOS',
  'Other',
}

export function formatPhoneNumber(
  cellPhoneCountryCode: string,
  cellPhoneNumber: string
): string {
  return cellPhoneCountryCode + cellPhoneNumber.replace(/\D/g, '')
}

/**
 * - To be used for email validation by yup (uses strict regex from yup v0.32.11)
 * - Allows empty values
 * - Example of usage: yup.string().test('email', tPage('emailError'), getEmailTestFunction),
 */
export function getEmailTestFunction(
  value: string | null | undefined
): boolean {
  // regex source: https://github.com/jquense/yup/blob/pre-v1/src/string.ts
  const EMAIL_REGEX =
    // eslint-disable-next-line no-control-regex, no-useless-escape
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
  return !value || EMAIL_REGEX.test(value)
}

/**
 * @param minMessage shown when password has less than 8 chars
 * @param spacesMessage shown when password starts/ends with a space char
 * @param requiredMessage shown when required check fails
 * @returns
 */
export function getNewPasswordValidator(
  minMessage: string,
  spacesMessage: string,
  requiredMessage: string
) {
  return yup
    .string()
    .min(8, () => minMessage)
    .matches(/^[\S]+.*[\S]+$/, spacesMessage) // don't move before length check (requires at least 2 chars to work correctly)
    .required(requiredMessage)
}

export function getOperatingSystem(): OperatingSystem {
  const ua = navigator.userAgent

  if (/android/i.test(ua)) {
    return OperatingSystem.Android
  } else if (/iPad|iPhone|iPod/.test(ua)) {
    return OperatingSystem.iOS
  } else {
    return OperatingSystem.Other
  }
}

export function hasAppUTMMedium(): boolean {
  return new URLSearchParams(window.location.search).get('utm_medium') === 'app'
}

const StateAvailabilityCallCopyMap: {
  [key in StateAvailability]: CallCopyVariation
} = {
  [StateAvailability.Available]: CallCopyVariation.Primary,
  [StateAvailability.Partial]: CallCopyVariation.PartialState,
  [StateAvailability.Unavailable]: CallCopyVariation.UnavailableState,
}

export function getStateAvailabilityVariation(
  stateUnavailability?: SignupContext['unavailability']['state']
): StateAvailability {
  let availability: StateAvailability = StateAvailability.Available
  if (stateUnavailability?.unavailablePrograms?.length) {
    if (stateUnavailability?.partial) {
      availability = StateAvailability.Partial
    } else {
      availability = StateAvailability.Unavailable
    }
  }
  return availability
}

export function getCallCopyVariation(
  isMinor: boolean,
  stateUnavailability?: SignupContext['unavailability']['state']
): CallCopyVariation {
  let availability: CallCopyVariation =
    StateAvailabilityCallCopyMap[
      getStateAvailabilityVariation(stateUnavailability)
    ]
  if (isMinor) {
    availability = CallCopyVariation.Minors
  }
  return availability
}

export const isDataConsentDisclosureRequired = (
  polyAddictions: TreatmentProgram[],
  selectedAddiction?: TreatmentProgram
): boolean => {
  return polyAddictions?.length > 0
    ? Boolean(
        polyAddictions?.includes(TreatmentProgram.Alcohol) ||
          polyAddictions?.includes(TreatmentProgram.Opioids) ||
          polyAddictions?.includes(TreatmentProgram.Stimulants) ||
          polyAddictions?.includes(TreatmentProgram.Cannabis)
      )
    : selectedAddiction
      ? selectedAddiction === TreatmentProgram.Alcohol ||
        selectedAddiction === TreatmentProgram.Opioids ||
        selectedAddiction === TreatmentProgram.Stimulants ||
        selectedAddiction === TreatmentProgram.Cannabis
      : false
}

/**
 * Returns incentive if valid (analyses query params & IncentiveType enum)
 */
export function getIncentive(): IncentiveType | null {
  // Query params of sd=, ed=, and incentive= could be available to display an incentive
  const queryParams = new URLSearchParams(window.location.search)
  const incentive = queryParams.get('incentive')
  const startDate = queryParams.get('sd')
  const endDate = queryParams.get('ed')

  // We receive date from query params as yyyy-MM-dd, convert to date object
  const today = new Date()
  const startDateParsed = startDate
    ? startOfDay(parse(startDate, 'yyyy-MM-dd', today))
    : null
  const endDateParsed = endDate
    ? endOfDay(parse(endDate, 'yyyy-MM-dd', today))
    : null
  const isBeforeOrEqual = (startDate: Date, endDate: Date): boolean =>
    isBefore(startDate, endDate) || isSameDay(startDate, endDate)

  const handledIncentives: Set<IncentiveType> = new Set(
    Object.values(IncentiveType)
  ) // creates a set from all IncentiveType enum values

  const canShowIncentive = !!(
    startDateParsed &&
    endDateParsed &&
    isBeforeOrEqual(startDateParsed, today) &&
    isBeforeOrEqual(today, endDateParsed) &&
    handledIncentives.has(incentive as IncentiveType)
  )

  return canShowIncentive ? (incentive as IncentiveType) : null
}

export function getSanitizedContext(
  context: SignupContext
): ProgressSignupContext {
  return {
    ...context,
    errors: {
      accessCode: {
        noMatchingEsiMembers: false,
      },
      signIn: {
        loginFailed: false,
      },
      signUp: {
        emailInUse: false,
        passwordLength: false,
        usernameInvalid: false,
      },
      dateOfBirth: {
        isNotMinimumAge: false,
      },
      // 'otp' property should NOT be sent
    },
    resetPassword: {
      cognitoClientId: '',
      cognitoUserPoolId: '',
      email: '',
      backendErrors: {
        codeMismatch: false,
        invalidPassword: false,
        sthWentWrong: false,
      },
    },
    user: {
      ...context.user,
      password: '',
    },
  }
}

/**
 * Function used for making sure that code is unreachable
 * @param x
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertUnreachable(x: never): never {
  throw new Error('Code should be unreachable')
}
