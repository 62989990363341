import { Box, CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useMachine } from '@xstate/react'
import { ReactElement, Suspense, lazy, useEffect, useState } from 'react'

import Footer from '~components/Footer'
import Header from '~components/Header'
import LoadingOverlay from '~components/LoadingOverlay'
import CallChoiceDecision from '~pages/CallChoiceDecision'
import EligibilityStep from '~pages/EligibilityStep'
import InternalError from '~pages/InternalError'
import SuccessfullyRegistered from '~pages/SuccessfullyRegistered'
import signupMachine from '~services/signupMachine'
import { updateUserProgressIfNeeded } from '~services/signupMachine/services'
import theme from '~theme'
import {
  OperatingSystem,
  getOperatingSystem,
  hasAppUTMMedium,
} from '~utils/helpers'

import './App.scss'
import './Pelago.scss'
import { addOneTrustCookieBanner } from './oneTrust.utils'

const queryClient = new QueryClient()

const accessCodePromise = import('~pages/AccessCode')
const AccessCode = lazy(() => accessCodePromise)

const careCoordinatorCallPromise = import('~pages/CareCoordinatorCall')
const CareCoordinatorCall = lazy(() => careCoordinatorCallPromise)

const companySelectorPromise = import('~pages/CompanySelector')
const CompanySelector = lazy(() => companySelectorPromise)

const downloadTheAppPromise = import('~pages/DownloadTheApp')
const DownloadTheApp = lazy(() => downloadTheAppPromise)

const esiCarrierSelectorPromise = import('~pages/EsiCarrierSelector')
const EsiCarrierSelector = lazy(() => esiCarrierSelectorPromise)

const loginPromise = import('~pages/Login')
const Login = lazy(() => loginPromise)

const loginWithPasswordPromise = import('~pages/LoginWithPassword')
const LoginWithPassword = lazy(() => loginWithPasswordPromise)

const noStateEligibilityPromise = import('~pages/NoStateEligibility')
const NoStateEligibility = lazy(() => noStateEligibilityPromise)

const signUpStep2Promise = import('~pages/SignUpStep2')
const SignUpStep2 = lazy(() => signUpStep2Promise)

const beInTouchPromise = import('~pages/BeInTouch')
const BeInTouch = lazy(() => beInTouchPromise)

const addictionSelectorPromise = import('~pages/AddictionSelector')
const AddictionSelector = lazy(() => addictionSelectorPromise)

const lighterAccountCreationPromise = import('~pages/LighterAccountCreation')
const LighterAccountCreation = lazy(() => lighterAccountCreationPromise)

const lighterOTPAccountCreationPromise = import(
  '~pages/LighterOTPAccountCreation'
)
const LighterOTPAccountCreation = lazy(() => lighterOTPAccountCreationPromise)

const lighterEligibilityNamePromise = import('~pages/LighterEligibilityName')
const LighterEligibilityName = lazy(() => lighterEligibilityNamePromise)

const lighterEligibilityBirthPromise = import('~pages/LighterEligibilityBirth')
const LighterEligibilityBirth = lazy(() => lighterEligibilityBirthPromise)

const lighterEligibilityAddressPromise = import(
  '~pages/LighterEligibilityAddress'
)
const LighterEligibilityAddress = lazy(() => lighterEligibilityAddressPromise)

const lighterPhoneNumberPromise = import('~pages/LighterPhoneNumber')
const LighterPhoneNumber = lazy(() => lighterPhoneNumberPromise)

const lighterEmailAddressPromise = import('~pages/LighterEmailAddress')
const LighterEmailAddress = lazy(() => lighterEmailAddressPromise)

const insurancePlanDetailsPromise = import('~pages/InsurancePlanDetails')
const InsurancePlanDetails = lazy(() => insurancePlanDetailsPromise)

const bookCallPromise = import('~pages/LighterBookCall')
const BookCall = lazy(() => bookCallPromise)

const otpCodePromise = import('~pages/OTPCode')
const OTPCode = lazy(() => otpCodePromise)

const resetPasswordPromise = import('~pages/ResetPassword')
const ResetPassword = lazy(() => resetPasswordPromise)

const resetPasswordEmailSentPromise = import('~pages/ResetPasswordEmailSent')
const ResetPasswordEmailSent = lazy(() => resetPasswordEmailSentPromise)

const resetPasswordNewOnePromise = import('~pages/ResetPasswordNewOne')
const ResetPasswordNewOne = lazy(() => resetPasswordNewOnePromise)

const resetPasswordCodeExpiredPromise = import(
  '~pages/ResetPasswordCodeExpired'
)
const ResetPasswordCodeExpired = lazy(() => resetPasswordCodeExpiredPromise)

const resetPasswordCompletedPromise = import('~pages/ResetPasswordCompleted')
const ResetPasswordCompleted = lazy(() => resetPasswordCompletedPromise)

const termsOfUsePromise = import('~pages/Docs/TermsOfUse')
const TermsOfUse = lazy(() => termsOfUsePromise)

const privacyPolicyPromise = import('~pages/Docs/PrivacyPolicy')
const PrivacyPolicy = lazy(() => privacyPolicyPromise)

const noticeOfPrivacyPracticesPromise = import(
  '~pages/Docs/NoticeOfPrivacyPractices'
)
const NoticeOfPrivacyPractices = lazy(() => noticeOfPrivacyPracticesPromise)

export default function App(): ReactElement {
  const [uiTags, setUiTags] = useState<string[]>([])
  const [state, send] = useMachine(signupMachine, {
    devTools: true,
  })

  useEffect(() => {
    // iOS webview should have ios and app utm medium.
    const isIOSWebView =
      getOperatingSystem() === OperatingSystem.iOS && hasAppUTMMedium()

    // Don't show the cookie banner if it's iOS webview.
    if (!isIOSWebView) {
      addOneTrustCookieBanner()
    }
  }, [])

  useEffect(() => {
    if (state.hasTag('ui')) {
      setUiTags(Array.from(state.tags))
      updateUserProgressIfNeeded(state)
    }
  }, [state.tags])

  return (
    <Suspense fallback={<LoadingOverlay state={state} />}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <Box display="flex" flexDirection="column" height="100vh">
            {state.hasTag('loading') && <LoadingOverlay state={state} />}
            <Header state={state} />
            <Box component="main" flexGrow={1}>
              {/* Original flow */}
              <>
                {uiTags.includes('accessCode') && (
                  <AccessCode state={state} send={send} />
                )}
                {uiTags.includes('companySelector') && (
                  <CompanySelector send={send} />
                )}
                {uiTags.includes('esiCarrierSelector') && (
                  <EsiCarrierSelector send={send} />
                )}
                {uiTags.includes('eligibilityStep') && (
                  <EligibilityStep send={send} state={state} />
                )}
                {uiTags.includes('signUpStep2') && (
                  <SignUpStep2 state={state} send={send} />
                )}
              </>

              {/* Lighter flow */}
              <>
                {uiTags.includes('lighterAccountCreation') && (
                  <LighterAccountCreation state={state} send={send} />
                )}
                {uiTags.includes('lighterOTPAccountCreation') && (
                  <LighterOTPAccountCreation state={state} send={send} />
                )}
                {uiTags.includes('lighterEligibilityName') && (
                  <LighterEligibilityName state={state} send={send} />
                )}
                {uiTags.includes('lighterEligibilityBirth') && (
                  <LighterEligibilityBirth state={state} send={send} />
                )}
                {uiTags.includes('lighterEligibilityAddress') && (
                  <LighterEligibilityAddress state={state} send={send} />
                )}
                {uiTags.includes('lighterPhoneNumber') && (
                  <LighterPhoneNumber state={state} send={send} />
                )}
                {uiTags.includes('lighterEmailAddress') && (
                  <LighterEmailAddress state={state} send={send} />
                )}
              </>

              {/* Health Plan flow */}
              <>
                {uiTags.includes('insurancePlanDetails') && (
                  <InsurancePlanDetails state={state} send={send} />
                )}
              </>

              {/* Shared */}
              <>
                {uiTags.includes('login') && (
                  <Login state={state} send={send} />
                )}
                {uiTags.includes('loginWithPassword') && (
                  <LoginWithPassword state={state} send={send} />
                )}
                {uiTags.includes('otpCode') && (
                  <OTPCode state={state} send={send} />
                )}
                {/* Reset password flow */}
                <>
                  {uiTags.includes('resetPassword') && (
                    <ResetPassword state={state} send={send} />
                  )}
                  {uiTags.includes('resetPasswordEmailSent') && (
                    <ResetPasswordEmailSent send={send} />
                  )}
                  {uiTags.includes('resetPasswordNewOne') && (
                    <ResetPasswordNewOne state={state} send={send} />
                  )}
                  {uiTags.includes('resetPasswordCodeExpired') && (
                    <ResetPasswordCodeExpired state={state} send={send} />
                  )}
                  {uiTags.includes('resetPasswordCompleted') && (
                    <ResetPasswordCompleted send={send} />
                  )}
                </>
                {(uiTags.includes('addictionSelector') ||
                  uiTags.includes('lighterAddictionSelector')) && (
                  <AddictionSelector state={state} send={send} />
                )}
                {(uiTags.includes('noStateEligibility') ||
                  uiTags.includes('lighterStateUnavailable')) && (
                  <NoStateEligibility state={state} send={send} />
                )}
                {uiTags.includes('callChoiceDecision') && (
                  <CallChoiceDecision send={send} />
                )}
                {uiTags.includes('bookCall') && (
                  <BookCall state={state} send={send} />
                )}
                {uiTags.includes('beInTouch') && <BeInTouch send={send} />}
                {uiTags.includes('downloadTheApp') && <DownloadTheApp />}
                {uiTags.includes('successfullyRegistered') && (
                  <SuccessfullyRegistered />
                )}
                {uiTags.includes('internalError') && (
                  <InternalError state={state} send={send} />
                )}
                {uiTags.includes('careCoordinatorCall') && (
                  <CareCoordinatorCall state={state} send={send} />
                )}

                {/* Docs */}
                {uiTags.includes('doc_termsOfUse') && <TermsOfUse />}
                {uiTags.includes('doc_privacyPolicy') && <PrivacyPolicy />}
                {uiTags.includes('doc_noticeOfPrivacyPractices') && (
                  <NoticeOfPrivacyPractices />
                )}
              </>
            </Box>
            <Footer state={state} send={send} />
          </Box>
        </QueryClientProvider>
      </ThemeProvider>
    </Suspense>
  )
}
