import { Box, Checkbox, FormControl, Link, MenuItem } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { format, isValid, parseISO } from 'date-fns'
import { enGB, enUS, enZA } from 'date-fns/locale'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import { getFixedT, t } from 'i18next'
import { ReactElement, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { UniversalI18nNamespace } from 'universal-i18n-namespace'
import * as yup from 'yup'

import Confidential from '~components/Confidential'
import DatePicker from '~components/DatePicker'
import FormHelperText from '~components/FormHelperText'
import InfoBanner from '~components/InfoBanner'
import Layout from '~components/Layout'
import PrimaryButton from '~components/PrimaryButton'
import StateSelector from '~components/StateSelector'
import TextField from '~components/TextField'
import { Country } from '~constants/types'
import { Address } from '~services/signupMachine/types/user'
import {
  calculateUserAge,
  getUsStateFromStateCode,
  isPastDate,
} from '~utils/helpers'
import { SendType, StateType } from '~utils/machineTypes'

import './EligibilityStep.scss'

interface FormValues {
  agree: boolean
  country: Address['country']
  dateOfBirth: string
  firstName: string
  lastName: string
  zaIdNumber: string
  postalCode: Address['postalCode']
  province: Address['province']
}

interface EligibilityStepProps {
  send: SendType
  state: StateType
}

/**
 * EligibilityStep
 */
export default function EligibilityStep({
  state,
  send,
}: EligibilityStepProps): ReactElement {
  const { user, company } = state.context
  const { t: tRoot, i18n } = useTranslation()
  const tPage = getFixedT(null, null, 'signUp1')
  const tForm = getFixedT(null, null, 'form')
  const tValues = getFixedT(null, UniversalI18nNamespace.Common, 'form.values')
  const [adapterLocale, setAdapterLocale] = useState<Locale>()
  const countryOptions = [
    [Country.ZA, tValues('za')],
    [Country.GB, tValues('uk')],
    [Country.US, tValues('us')],
  ]

  const validationSchema = yup.object({
    country: yup.string().required(),
    province: yup.string().when('country', {
      is: Country.US,
      then: (schema) => schema.required(tForm('errors.enterProvince')),
    }),
    postalCode: yup
      .string()
      .required(tForm('errors.enterPostalCode'))
      .when('country', {
        is: Country.GB,
        then: (schema) =>
          schema
            // regex via https://stackoverflow.com/a/51885364/3122450
            .matches(
              /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/,
              tForm('errors.postalCodeInvalid')
            )
            .min(5, tForm('errors.postalCodeTooShort', { length: 5 }))
            .max(8, tForm('errors.postalCodeTooLong', { length: 8 })),
      })
      .when('country', {
        is: Country.US,
        then: (schema) =>
          schema
            .matches(/^[0-9]+$/, tForm('errors.onlyDigits'))
            .min(5, tForm('errors.postalCodeWrongLength', { length: 5 }))
            .max(5, tForm('errors.postalCodeWrongLength', { length: 5 })),
      })
      .when('country', {
        is: Country.ZA,
        then: (schema) =>
          schema
            .matches(/^[0-9]+$/, tForm('errors.onlyDigits'))
            .min(4, tForm('errors.postalCodeWrongLength', { length: 4 }))
            .max(4, tForm('errors.postalCodeWrongLength', { length: 4 })),
      }),
    firstName: yup.string().trim().required(tForm('errors.enterFirstName')),
    lastName: yup.string().trim().required(tForm('errors.enterLastName')),
    agree: yup.bool().oneOf([true], tForm('errors.clickAgree')),
    zaIdNumber: yup.string().when('$requireSouthAfricaIDNumber', {
      is: true,
      then: (schema) =>
        schema
          .required(tForm('errors.isRequired'))
          .matches(/^[0-9]+$/, tForm('errors.onlyDigits'))
          .min(
            13,
            tForm('errors.memberIdLengthInvalid', {
              requiredLength: '13',
            })
          )
          .max(
            13,
            tForm('errors.memberIdLengthInvalid', {
              requiredLength: '13',
            })
          ),
    }),
    dateOfBirth: yup
      .string()
      .required(tForm('errors.enterDateOfBirth'))
      .test(
        'isPastDate',
        tForm('errors.isPastDate'),
        (dateOfBirth) => !!dateOfBirth && isPastDate(dateOfBirth)
      )
      .when('country', {
        is: Country.US,
        then: (schema) =>
          schema.when('province', ([province]: string[], schema) => {
            if (province) {
              const ageOfMajority =
                getUsStateFromStateCode(province)?.ageOfMajority || 18
              return schema.test(
                'isMinimumAge',
                tForm('errors.minimumAge', { age: ageOfMajority }),
                (dateOfBirth: string) =>
                  !!dateOfBirth &&
                  (company.minorsProgramEnabled ||
                    calculateUserAge(dateOfBirth) >= ageOfMajority)
              )
            } else {
              return schema
            }
          }),
        otherwise: (schema) =>
          schema.test(
            'isMinimumAge',
            tForm('errors.minimumAge'),
            (dateOfBirth) =>
              !!dateOfBirth && calculateUserAge(dateOfBirth) >= 18
          ),
      }),
  })

  const formik = useFormik({
    initialValues: {
      agree: false,
      country: user.address.country,
      dateOfBirth: user.dateOfBirth,
      firstName: user.firstName,
      lastName: user.lastName,
      zaIdNumber: user.zaIdNumber,
      postalCode: user.address.postalCode,
      province: user.address.province,
    },
    validateOnChange: true,
    validateOnBlur: true,
    validate: (values: FormValues) => {
      try {
        validateYupSchema<FormValues>(values, validationSchema, true, {
          requireSouthAfricaIDNumber: company.requireSouthAfricaIDNumber,
        })
      } catch (err) {
        return yupToFormErrors(err)
      }

      return {}
    },
    onSubmit: (formValues: FormValues) => {
      send({
        type: 'SET_ELID',
        value: {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          country: formValues.country,
          province: formValues.province,
          postalCode: formValues.postalCode,
          dateOfBirth: formValues.dateOfBirth,
          ...(formValues.zaIdNumber && { zaIdNumber: formValues.zaIdNumber }),
        },
      })
    },
  })

  // If the country field changes, we need to update the translations and date
  // field order.
  useEffect(() => {
    if (formik.values.country === Country.GB) {
      i18n.changeLanguage('en-GB')
      setAdapterLocale(enGB)
    } else if (formik.values.country === Country.ZA) {
      i18n.changeLanguage('en-ZA')
      setAdapterLocale(enZA)
    } else {
      i18n.changeLanguage('en-US')
      setAdapterLocale(enUS)
    }
  }, [formik.values.country])

  // If there are errors and the country has been changed, we need to revalidate
  // to update the error messages. Language updates happen in the render cycle
  // _after_ the country gets updated, so we observe changes separately.
  useEffect(() => {
    if (Object.keys(formik.errors).length) {
      formik.validateForm()
    }
  }, [i18n.language])

  return (
    <Layout className="eligibility-step">
      <form onSubmit={formik.handleSubmit}>
        <h1 className="pel-typography-h5-bold">{tPage('firstLetsCheck')}</h1>
        <InfoBanner
          header={tPage('infoBannerHeader')}
          bannerContent={tPage('infoBannerContent')}
          mb={2}
        ></InfoBanner>
        <Box className="eligibility-step__input">
          <TextField
            label={tForm('fields.firstName')}
            name="firstName"
            autoComplete="given-name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            error={!!formik.touched.firstName && !!formik.errors.firstName}
            helperText={formik.touched.firstName ? formik.errors.firstName : ''}
          />
        </Box>
        <Box className="eligibility-step__input">
          <TextField
            label={tForm('fields.lastName')}
            name="lastName"
            autoComplete="family-name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            error={!!formik.touched.lastName && !!formik.errors.lastName}
            helperText={formik.touched.lastName ? formik.errors.lastName : ''}
          />
        </Box>
        {formik.values.country === Country.US ? (
          <>
            <Box className="eligibility-step__input">
              <TextField
                label={tForm('fields.country')}
                name="country"
                select
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.country}
                error={!!formik.touched.country && !!formik.errors.country}
                helperText={formik.touched.country ? formik.errors.country : ''}
                disabled={company.companyId ? true : false}
                data-testid="country"
              >
                {countryOptions.map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box className="eligibility-step__input">
              <TextField
                label={tForm('fields.postalCode')}
                name="postalCode"
                autoComplete="postal-code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postalCode}
                error={
                  !!formik.touched.postalCode && !!formik.errors.postalCode
                }
                helperText={
                  formik.touched.postalCode ? formik.errors.postalCode : ''
                }
              />
            </Box>

            <Box className="eligibility-step__input">
              <StateSelector
                onChange={(value) => formik.setFieldValue('province', value)}
                onBlur={formik.handleBlur}
                value={formik.values.province}
                error={!!formik.touched.province && !!formik.errors.province}
                helperText={
                  formik.touched.province ? formik.errors.province || '' : ''
                }
              />
            </Box>
          </>
        ) : (
          <>
            <Box className="eligibility-step__input">
              <TextField
                label={tForm('fields.country')}
                name="country"
                select
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.country}
                error={!!formik.touched.country && !!formik.errors.country}
                helperText={formik.touched.country ? formik.errors.country : ''}
                disabled={company.companyId ? true : false}
                data-testid="country"
              >
                {countryOptions.map(([value, label]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            <Box className="eligibility-step__input">
              <TextField
                label={tForm('fields.postalCode')}
                name="postalCode"
                autoComplete="postal-code"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postalCode}
                error={
                  !!formik.touched.postalCode && !!formik.errors.postalCode
                }
                helperText={
                  formik.touched.postalCode ? formik.errors.postalCode : ''
                }
              />
            </Box>
          </>
        )}
        {company.requireSouthAfricaIDNumber && (
          <Box className="eligibility-step__input">
            <TextField
              label={tForm('fields.zaMemberId')}
              name="zaIdNumber"
              autoComplete="za-id-number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.zaIdNumber}
              error={!!formik.touched.zaIdNumber && !!formik.errors.zaIdNumber}
              helperText={
                formik.touched.zaIdNumber ? formik.errors.zaIdNumber : ''
              }
            />
          </Box>
        )}
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={adapterLocale}
        >
          <Box className="eligibility-step__input">
            <DatePicker
              label={tPage('dateOfBirth')}
              views={['year', 'month', 'day']}
              onChange={(val) =>
                formik.setFieldValue(
                  'dateOfBirth',
                  val && isValid(val) ? format(val, 'yyyy-MM-dd') : undefined
                )
              }
              onClose={() => formik.setFieldTouched('dateOfBirth', true, false)}
              value={parseISO(formik.values.dateOfBirth || '')}
              slotProps={{
                field: {
                  name: 'dateOfBirth', // needed for tracking purposes
                },
                textField: {
                  onBlur: () => formik.setFieldTouched('dateOfBirth'),
                  error:
                    !!formik.touched.dateOfBirth && !!formik.errors.dateOfBirth,
                  helperText:
                    !!formik.touched.dateOfBirth && formik.errors.dateOfBirth,
                },
              }}
            />
          </Box>
        </LocalizationProvider>
        {/* Agreement checkbox */}
        <FormControl
          required
          error={!!formik.touched.agree && !!formik.errors.agree}
          component="fieldset"
          variant="standard"
        >
          <Box className="eligibility-step__terms">
            <Checkbox
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.agree}
              id="agree"
              inputProps={{
                'aria-labelledby': 'agree-label',
              }}
            />
            <p id="agree-label" className="eligibility-step__terms__text">
              <Trans
                i18nKey="common:global.consent.termsAndPrivacyConsent"
                components={{
                  termsLink: (
                    <Link
                      href={t('common:global.docLinks.termsAndConditions')}
                      target="_blank"
                    />
                  ),
                  privacyLink: (
                    <Link
                      href={t('common:global.docLinks.privacyPolicy')}
                      target="_blank"
                    />
                  ),
                  noticeOfPrivacyPracticesLink: (
                    <Link
                      href={t(
                        'common:global.docLinks.noticeOfPrivacyPractices'
                      )}
                      target="_blank"
                    />
                  ),
                  visuallyHidden: <span className="visually-hidden" />,
                }}
              />
              {company.requireSouthAfricaIDNumber
                ? tPage('iAgree.discoveryVitality')
                : null}
            </p>
          </Box>
          {!!formik.touched.agree && !!formik.errors.agree && (
            <FormHelperText id="agree-error">
              {formik.errors.agree}
            </FormHelperText>
          )}
        </FormControl>
        {/* Submit button */}
        <Box className="eligibility-step__submit-button">
          <PrimaryButton type="submit" minwidth="100%">
            {tRoot('common:global.continue')}
          </PrimaryButton>
        </Box>
        <Confidential />
      </form>
    </Layout>
  )
}
