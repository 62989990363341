import { DoneEventObject, Event, assign } from 'xstate'

import { INITIAL_USER } from './initialUser'
import { SignupContext } from './types/signupContext'
import { User } from './types/user'

// TYPES
type UpdateLighterMemberData = {
  type: 'UPDATE_LIGHTER_MEMBER_DATA'
  value: Partial<User>
}

type ClearLoginUserData = {
  type: 'CLEAR_LOGIN_USER_DATA'
}

type ClearUserData = {
  type: 'CLEAR_USER_DATA'
}

export type LighterEvents =
  | UpdateLighterMemberData
  | ClearUserData
  | ClearLoginUserData

// ACTIONS
export const updateLighterMemberData = assign<
  SignupContext,
  UpdateLighterMemberData
>({
  user: (context, event) => ({ ...context.user, ...event.value }),
})

export const clearUserData = assign<
  SignupContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ClearUserData | DoneEventObject | Event<any>
>({
  user: () => ({ ...INITIAL_USER }),
})

export const clearLoginUserData = assign<
  SignupContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ClearLoginUserData | Event<any>
>({
  user: (context) => ({
    ...context.user,
    password: INITIAL_USER.password,
  }),
})
